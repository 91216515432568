import React from 'react';
import Layout from 'templates/Layout/Layout';
import PageHeading from 'components/_shared/PageHeading/PageHeading';
import ContentDataInformationOffice from 'content/privacyPolicy-informationOffice.json';
import InformationOffice from 'components/_shared/InformationOffice/InformationOffice';
import CustomText from 'components/_shared/CustomText/CustomText';
import ContentDataPrivacyPolice from 'content/privacyPolicy.json';

const Page = () => (
  <Layout metaTitle="Polityka prywatności" metaDescription="Nasza polityka prywatności.">
    <PageHeading text="Polityka prywatności" />
    <CustomText text={ContentDataPrivacyPolice.content} />
    <InformationOffice contentData={ContentDataInformationOffice} />
  </Layout>
);

export default Page;

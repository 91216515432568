import React from 'react';
import PropTypes from 'prop-types';
import { StyledWrapper, StyledContainer } from './CustomText.styled';

const CustomText = ({ text }) => (
  <StyledWrapper>
    <StyledContainer dangerouslySetInnerHTML={{ __html: text }} />
  </StyledWrapper>
);

CustomText.propTypes = {
  text: PropTypes.string.isRequired,
};

export default CustomText;

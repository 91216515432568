import styled from 'styled-components';
import { fontWeight, colors } from 'utils/variables';
import Container from 'components/_shared/Container/Container';

export const StyledWrapper = styled.div`
  position: relative;
`;

export const StyledContainer = styled(Container)`
  font-size: 1.6rem;
  line-height: 3.8rem;
  font-weight: ${fontWeight.medium};
  color: ${colors.grayCobalt};
  padding: 4.8rem 0;
`;

export default StyledWrapper;
